/* Import Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* General Styles */
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  overscroll-behavior: none !important;
}

body {
  overflow-x: hidden;
  color: #fff;
}
input {
  font-family: "Montserrat", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiTabs-flexContainer button.Mui-selected {
  background: #00ffad1a;
}

/* Change the white to transparent */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

input:focus::placeholder {
  color: transparent !important;
}

::placeholder {
  opacity: 0.7 !important;
}
